import User from './User'
export default class SavedQueries {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //UserID is required
      UserID: 0,
      //Report is required
      Report: '',
      //Title is required
      Title: '',
      //Query is required
      Query: '',
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.UserID = obj.UserID
    this.Report = obj.Report
    this.Title = obj.Title
    this.Query = obj.Query
    this.User = new User(obj.User || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.User = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.User = null
    return obj
  }
}
