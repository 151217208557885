<template>
  <!-- SAVED QUERIES -->
  <BasePanelWithHeader collapsable>
    <template v-slot:header>
      Saved Queries<BaseTooltipButton
        v-if="copyAvailable"
        icon="mdi-content-paste"
        iconColor="white"
        @click.stop="loadDialog = true"
      >
        Load Query From Text
      </BaseTooltipButton>
    </template>
    <v-card-text>
      <v-form @submit.prevent>
        <v-row>
          <v-col sm="12" md="6">
            <v-select
              :items="availableSavedQueries"
              item-text="Title"
              item-value="ID"
              label="Saved Queries"
              v-model="selectedQueryID"
              @change="selectQuery"
              clearable
            >
              <template v-slot:item="{item, attrs, on}">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.Title }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-row>
                      <BaseTooltipButton
                        v-if="copyAvailable"
                        icon="mdi-link"
                        iconColor="primary"
                        @click.stop="copyQuery(item)"
                        >Copy query to clipboard
                      </BaseTooltipButton>
                      <v-tooltip bottom v-if="item.ID && item.ID > 0">
                        <template v-slot:activator="{on}">
                          <v-btn color="primary" v-on="on" icon>
                            <v-icon
                              class="clickable"
                              color="primary"
                              @click.stop="deleteConfirm(item)"
                              >mdi-trash-can</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Delete Query</span>
                      </v-tooltip>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
          <v-col>
            <v-btn color="primary" @click="dialog = true">
              <v-icon left>mdi-content-save</v-icon>Save New Query
            </v-btn>
          </v-col>
        </v-row>
        <v-dialog v-model="dialog" width="450">
          <v-card class="elevation-3">
            <v-card-title class="primary">
              <h3 class="hpHeading white--text">Enter New Queries</h3>
            </v-card-title>
            <v-card-text class="pa-4">
              <v-row>
                <v-col sm="12">
                  <v-text-field
                    label="Query Name"
                    v-model="savedQueriesTitle"
                  ></v-text-field>
                </v-col>
                <v-col sm="12">
                  <v-card-actions>
                    <v-btn color="primary" @click="saveQuery">Accept</v-btn>
                    <v-btn outlined color="primary" @click="dialog = !dialog"
                      >Cancel</v-btn
                    >
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="loadDialog" scrollable width="850">
          <v-card class="elevation-3">
            <v-card-title class="primary">
              <h3 class="hpHeading white--text">Paste Query</h3>
            </v-card-title>
            <v-card-text class="pa-4">
              <v-row>
                <v-col sm="12"
                  ><v-textarea auto-grow v-model="queryToLoad"></v-textarea
                ></v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                :disabled="!queryIsValid"
                @click="loadQuery"
                color="primary"
                >Load</v-btn
              >
              <v-btn outlined color="primary" @click="loadDialog = !loadDialog"
                >Cancel</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="deleteDialog" scrollable width="350">
          <v-card class="elevation-3">
            <v-card-title class="primary">
              <h3 class="hpHeading white--text">Confirm?</h3>
            </v-card-title>
            <v-card-text class="pa-4">
              <v-row>
                <v-col sm="12"
                  >Are you sure you want to delete this query?</v-col
                >
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" @click="deleteDialog = !deleteDialog"
                >Cancel</v-btn
              >
              <v-btn @click="deleteQueries" outlined color="primary"
                >Delete</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-card-text>
  </BasePanelWithHeader>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import {authMethods, authComputed} from '@state/helpers'
import SavedQueries from '@classes/SavedQueries'
export default {
  props: {
    reportName: {
      required: true,
      type: String,
    },
    searchParams: {
      required: true,
      type: Object,
    },
    copyAvailable: {
      type: Boolean,
      default: false,
    },
    additionalQueries: {
      type: Array,
    },
  },
  data() {
    return {
      valid: true,
      queryToLoad: '',
      loadDialog: false,
      panel: [0, 1],
      savedQueriesTitle: '',
      savedQueries: [],
      deleteQuery: {},
      dialog: false,
      deleteDialog: false,
      selectedQueryID: 0,
    }
  },
  created() {
    this.getQueriesSaved()
  },
  computed: {
    ...authComputed,
    queryIsValid() {
      var jsonString = this.queryToLoad
      var valid = false
      if (jsonString) {
        try {
          var a = JSON.parse(jsonString)
          valid = true
        } catch (e) {
          valid = false // error in the above string (in this case, yes)!
        }
      } else {
        valid = false
      }
      return valid
    },
    selectedQuery() {
      return this.availableSavedQueries.find(
        (q) => q.ID == this.selectedQueryID
      )
    },
    availableSavedQueries() {
      let list = this.savedQueries.filter(
        (item) => item.Report == this.reportName
      )
      let list2 = []
      if (this.additionalQueries && this.additionalQueries.length > 0) {
        this.additionalQueries.forEach((r) => {
          let query = r.Query
          list2.push({Title: r.Title, ID: r.ID, Query: JSON.stringify(query)})
        })
        list2.sort((a, b) => b.ID - a.ID)
      }
      return list2.concat(list)
    },
  },
  methods: {
    createSavedQueries: call('savedQueries/createSavedQueries'),
    deleteSavedQueries: call('savedQueries/deleteSavedQueries'),
    handleError: call('errors/handleError'),
    saveQuery() {
      var savedQueries = new SavedQueries()
      savedQueries.UserID = this.currentUser.ID
      savedQueries.Title = this.savedQueriesTitle
      savedQueries.Report = this.reportName
      this.queryIsModified = false
      savedQueries.Query = JSON.stringify(this.searchParams)
      this.createSavedQueries(savedQueries.root()).then((res) => {
        this.savedQueries.push(res.data)
      })
      this.modalText = 'Save Query'
      this.dialog = !this.dialog
    },
    copyQuery(item) {
      this.$copyText(JSON.stringify(JSON.parse(item.Query), undefined, 2))
    },
    getQueriesSaved() {
      if (this.currentUser) {
        const url = `SavedQueries/User/${this.currentUser.ID}`
        this.$axios.get(url).then(
          (res) => {
            this.savedQueries = res.data
          },
          (error) => {
            this.handleError(error)
            console.error(error)
          }
        )
      }
    },
    loadQuery() {
      var jsonString = this.queryToLoad
      this.$emit('selectQuery', {Query: jsonString})
      this.queryToLoad = ''
      this.loadDialog = false
    },
    selectQuery() {
      this.queryIsModified = false
      this.$emit('selectQuery', this.selectedQuery)
    },
    deleteConfirm(item) {
      this.deleteQuery = item
      this.deleteDialog = true
    },
    deleteQueries() {
      let index = this.savedQueries.findIndex(
        (q) => q.ID == this.deleteQuery.ID
      )
      this.deleteSavedQueries(this.deleteQuery).then(
        (res) => {
          this.savedQueries.splice(index, 1)
        },
        (err) => {}
      )
      this.deleteDialog = false
    },
  },
  watch: {},
}
</script>
<style scoped>
</style>